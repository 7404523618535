import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Mirador from "./Mirador";
import { Card, Table } from "antd";
import axios from "axios";

const { Column } = Table;

interface Writer {
  name: string;
  patronymic: string;
  title: string;
  trismegistos_person: string;
  rufini: string;
}

interface Papyrus {
  trismegistos: string;
  language: string;
  content: string;
  provenance: string;
}

interface PapyrusWriter {
  writer: Writer;
  papyrus: Papyrus;
  descriptor: string;
  hand: string;
  paleographic_assignation: string;
}

interface ImageArea {
  name: string;
  contains: string;
  fragment_id: string;
  papyrus_writers: PapyrusWriter[];
}

interface Data {
  imageName: string;
  imageAreas: ImageArea[];
}

const Image = () => {
  const [data, setData] = useState<Data>({
    imageName: "",
    imageAreas: [],
  });
  const [clickedImageArea, setClickedImageArea] = useState<ImageArea | null>(null);
  const [writerData, setWriterData] = useState<Writer | null>(null);

  const params = useParams<{ id: string }>();

  const fetchImageData = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://db.d-scribes.philhist.unibas.ch/api/showcase/image/${params.id}`
      );
      const imageData = response.data[0];
      setData({ imageName: imageData.name, imageAreas: imageData.image_areas });
    } catch (error) {
      console.error("Error fetching image data:", error);
    }
  }, [params.id]);

  useEffect(() => {
    fetchImageData();
  }, [fetchImageData]);

  const handleImageAreaClick = (name: string) => {
    const area = data.imageAreas.find((area) => area.name === name);
    setClickedImageArea(area || null);
  };

  const handleWriterClick = (writer: Writer) => {
    setWriterData(writer);
  };

  return (
    <>
      <h3 style={{ textAlign: "center" }}>{data.imageName}</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "80vh",
        }}
      >
        <Card
          hoverable
          style={{ width: "80vw", height: "80vh" }}
          cover={
            <Mirador
              config={{
                id: "mirador",
                url: `https://db.d-scribes.philhist.unibas.ch/api/showcase/manifest/${params.id}`,
              }}
            />
          }
        />
      </div>

      <Table dataSource={data.imageAreas} rowKey="name" pagination={false}>
        <Column
          title="Name"
          dataIndex="name"
          render={(name: string) => (
            <a style={{ color: "#2192FF" }} onClick={() => handleImageAreaClick(name)}>
              {name}
            </a>
          )}
        />
        <Column title="Contains" dataIndex="contains" />
        <Column title="Fragment" dataIndex="fragment_id" />
        <Column
          title="Writer"
          dataIndex="papyrus_writers"
          render={(writers: PapyrusWriter[]) =>
            writers.map(({ writer }) => (
              <a
                key={writer.name}
                style={{ color: "#2192FF" }}
                onClick={() => handleWriterClick(writer)}
              >
                {writer.name}
              </a>
            ))
          }
        />
      </Table>

      {clickedImageArea && (
        <Table dataSource={[clickedImageArea]} rowKey="name" pagination={false}>
          <Column
            title="Descriptor"
            render={(record: ImageArea) =>
              record.papyrus_writers.map(({ descriptor }) => <div key={descriptor}>{descriptor}</div>)
            }
          />
          <Column
            title="Trismegistos"
            render={(record: ImageArea) =>
              record.papyrus_writers.map(({ papyrus }) => (
                <div key={papyrus.trismegistos}>{papyrus.trismegistos}</div>
              ))
            }
          />
          <Column
            title="Language"
            render={(record: ImageArea) =>
              record.papyrus_writers.map(({ papyrus }) => <div key={papyrus.language}>{papyrus.language}</div>)
            }
          />
          <Column
            title="Content"
            render={(record: ImageArea) =>
              record.papyrus_writers.map(({ papyrus }) => <div key={papyrus.content}>{papyrus.content}</div>)
            }
          />
          <Column
            title="Provenance"
            render={(record: ImageArea) =>
              record.papyrus_writers.map(({ papyrus }) => <div key={papyrus.provenance}>{papyrus.provenance}</div>)
            }
          />
          <Column
            title="Hand"
            render={(record: ImageArea) =>
              record.papyrus_writers.map(({ hand }) => <div key={hand}>{hand}</div>)
            }
          />
          <Column
            title="Paleographic Assignation"
            render={(record: ImageArea) =>
              record.papyrus_writers.map(({ paleographic_assignation }) => (
                <div key={paleographic_assignation}>{paleographic_assignation}</div>
              ))
            }
          />
        </Table>
      )}

      {writerData && (
        <Table dataSource={[writerData]} rowKey="name" pagination={false}>
          <Column title="Writer Name" dataIndex="name" />
          <Column title="Patronymic" dataIndex="patronymic" />
          <Column title="Title" dataIndex="title" />
          <Column title="Trismegistos" dataIndex="trismegistos" />
          <Column title="Rufini" dataIndex="rufini" />
        </Table>
      )}
    </>
  );
};

export default Image;
