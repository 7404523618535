import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, List, Input, Space, Divider, Spin, message } from "antd";
import axios from "axios";

const { Search } = Input;

// Define types for data and item
interface Item {
  id: string;
  name: string;
}

const Dioscorus: React.FC = () => {
  const [data, setData] = useState<Item[] | null>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory(); // Use useHistory instead of useNavigate

  // Fetch initial data
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://db.d-scribes.philhist.unibas.ch/api/showcase/dioscorus")
      .then((response) => setData(response.data))
      .catch((error) => {
        console.error(error);
        message.error("Failed to fetch data.");
      })
      .finally(() => setLoading(false));
  }, []);

  // Function to search data by name
  const searchByName = useCallback((value: string) => {
    setLoading(true);
    axios
      .get(`https://db.d-scribes.philhist.unibas.ch/api/showcase/dioscorus/${value}`)
      .then((response) => setData(response.data))
      .catch((error) => {
        console.error(error);
        message.error("Search failed. Please try again.");
      })
      .finally(() => setLoading(false));
  }, []);

  // Render list of data
  const renderData = useCallback(() => {
    if (!data) return null;
    return (
      <div style={{ width: "60%", margin: "0 auto" }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <a
                    style={{ fontSize: 22 }}
                    onClick={() => history.push(`/papyri/${item.id}`)}
                  >
                    {item.name}
                  </a>
                }
              />
              <Button onClick={() => history.push(`/papyri/${item.id}`)}>Show</Button>
            </List.Item>
          )}
        />
      </div>
    );
  }, [data, history]);

  return (
    <div>
      <Search
        placeholder="Search by name"
        allowClear
        enterButton="Search"
        size="large"
        onSearch={searchByName}
      />
      <Divider />
      <Space wrap style={{ display: "flex", justifyContent: "center" }}>
        <h4>
          Get{" "}
          <a
            style={{ color: "#2192FF" }}
            onClick={() => history.push(`/listWriters/1`)}
          >
            papyrus
          </a>{" "}
          by writer
        </h4>
      </Space>
      <Divider />
      <Space wrap style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={() => history.push(`/papyrus/subscription/1`)}>
          Subscription
        </Button>
        <Button onClick={() => history.push(`/papyrus/paelographicAssignation/1`)}>
          Paelographic Assignation
        </Button>
        <Button onClick={() => history.push(`/papyrus/securelyAttributedText/1`)}>
          Securely Attributed Text
        </Button>
      </Space>
      <Divider />
      {/* Render loading spinner or data */}
      {loading ? (
        <Spin size="large" style={{ display: "block", textAlign: "center", marginTop: 50 }} />
      ) : (
        renderData()
      )}
    </div>
  );
};

export default Dioscorus;
