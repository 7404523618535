import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Input, List, Space, Divider, Spin, Alert } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";

const { Search } = Input;

interface PapasData {
  id: string;
  name: string;
}

const Papas: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<PapasData[] | null>(null);
  const [authFailed, setAuthFailed] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");
  const auth = localStorage.getItem("auth");

  useEffect(() => {
    if (auth === "true" && email && password) {
      handleLogin(email, password);
    }
  }, [auth, email, password]);

  const handleLogin = async (email: string, password: string) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://db.d-scribes.philhist.unibas.ch/api/showcase/papas?email=${email}&password=${password}`
      );

      if (response.data.status !== 401) {
        setData(response.data);
        localStorage.setItem("auth", "true");
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        setAuthFailed(false);
      } else {
        setAuthFailed(true);
        localStorage.setItem("auth", "false");
      }
    } catch (error) {
      console.error("Login error:", error);
      setAuthFailed(true);
      localStorage.setItem("auth", "false");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (value: string) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://db.d-scribes.philhist.unibas.ch/api/showcase/papas/${value}?email=${email}&password=${password}`
      );
      setData(response.data);
    } catch (error) {
      console.error("Search error:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderList = useCallback(() => {
    if (!data) return null;

    return (
      <div style={{ width: "60%", margin: "0 auto" }}>
        <Search
          placeholder="Search by name"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={handleSearch}
        />
        <Divider />
        <Space wrap style={{ display: "flex", justifyContent: "center" }}>
          <h4>
            Get{" "}
            <a
              style={{ color: "#2192FF" }}
              onClick={() => history.push(`/listWriters/2`)}
            >
              papyrus
            </a>{" "}
            by writer
          </h4>
        </Space>
        <Divider />
        <Space wrap style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => history.push(`papyrus/subscription/2`)}>
            Subscription
          </Button>
          <Button
            onClick={() => history.push(`papyrus/paelographicAssignation/2`)}
          >
            Paelographic Assignation
          </Button>
          <Button
            onClick={() => history.push(`papyrus/securelyAttributedText/2`)}
          >
            Securely Attributed Text
          </Button>
        </Space>
        <Divider />
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <a
                    style={{ fontSize: 22 }}
                    onClick={() => history.push(`/papyri/${item.id}`)}
                  >
                    {item.name}
                  </a>
                }
              />
              <Button onClick={() => history.push(`/papyri/${item.id}`)}>
                Show
              </Button>
            </List.Item>
          )}
        />
      </div>
    );
  }, [data, history]);

  const renderLoginForm = () => {
    const onFinish = (values: { email: string; password: string }) => {
      handleLogin(values.email, values.password);
    };

    const onFinishFailed = (errorInfo: any) => {
      console.error("Failed:", errorInfo);
    };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
        }}
      >
        <Form
          name="login"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          {authFailed && (
            <Alert
              message="Authentication Failed"
              description="Please check your username or password."
              type="error"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const isAuthenticated = auth === "true";

  return (
    <div>
      {loading ? (
        <Spin tip="Loading..." />
      ) : isAuthenticated ? (
        renderList()
      ) : (
        renderLoginForm()
      )}
    </div>
  );
};

export default Papas;
