import React, { useEffect, useState, useRef } from "react";
import { Select, Empty, Modal, Slider } from "antd";

const { Option } = Select;

// Creates a snippet from the given image and bbox
function createSnippetDataUrl(image, ann) {
  if (!image || !ann?.bbox) return null;
  const [x, y, w, h] = ann.bbox;

  // Safeguard boundaries
  const sx = Math.max(0, x);
  const sy = Math.max(0, y);
  const sw = Math.min(w, image.width - sx);
  const sh = Math.min(h, image.height - sy);
  if (sw <= 0 || sh <= 0) return null;

  // Draw to an offscreen canvas, return PNG data URL
  const offCanvas = document.createElement("canvas");
  offCanvas.width = sw;
  offCanvas.height = sh;
  const ctx = offCanvas.getContext("2d");
  ctx.drawImage(image, sx, sy, sw, sh, 0, 0, sw, sh);
  return offCanvas.toDataURL("image/png");
}

export default function BaseTypeExplorer({
  image,
  annotations,
  categories,
  getCategoryName,
}) {
  // Grouped data: { categoryName: { baseType: [annotations...] } }
  const [groupedData, setGroupedData] = useState({});
  // Cache for snippet images
  const [snippets, setSnippets] = useState({});

  // Current filters
  const [selectedExplorerCategory, setSelectedExplorerCategory] = useState("All");
  const [selectedExplorerBaseType, setSelectedExplorerBaseType] = useState("All");

  // Modal state: bounding box preview
  const [previewVisible, setPreviewVisible] = useState(false);
  const [selectedAnnForPreview, setSelectedAnnForPreview] = useState(null);
  const [scale, setScale] = useState(2.5); // Zoom in the modal

  // Refs to canvas and scroll container inside modal
  const modalCanvasRef = useRef(null);
  const scrollContainerRef = useRef(null);

  // Build grouped data on mount/changes
  useEffect(() => {
    if (!annotations || annotations.length === 0 || !image) {
      setGroupedData({});
      return;
    }
    const grouping = {};
    annotations.forEach((ann) => {
      const catName = getCategoryName(ann.category_id);
      if (ann.tags?.BaseType?.length > 0) {
        ann.tags.BaseType.forEach((bt) => {
          grouping[catName] = grouping[catName] || {};
          grouping[catName][bt] = grouping[catName][bt] || [];
          grouping[catName][bt].push(ann);
        });
      }
    });
    setGroupedData(grouping);
  }, [annotations, image, getCategoryName]);

  // Lazy snippet creation per annotation
  const getSnippetForAnn = (ann) => {
    if (snippets[ann.id]) return snippets[ann.id];
    const dataUrl = createSnippetDataUrl(image, ann);
    if (dataUrl) {
      setSnippets((prev) => ({ ...prev, [ann.id]: dataUrl }));
      return dataUrl;
    }
    return null;
  };

  // Open modal on snippet click
  const handleSnippetClick = (ann) => {
    setSelectedAnnForPreview(ann);
    setScale(2.5);
    setPreviewVisible(true);
  };

  // Draw bounding box in modal, auto-scroll to it
  useEffect(() => {
    if (!previewVisible || !selectedAnnForPreview || !image) return;

    // Draw the zoomed image + bbox
    const drawCanvas = () => {
      const canvas = modalCanvasRef.current;
      if (!canvas) return;
      const [x, y, w, h] = selectedAnnForPreview.bbox;

      canvas.width = image.width * scale;
      canvas.height = image.height * scale;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        0, 0,
        image.width, image.height,
        0, 0,
        canvas.width, canvas.height
      );

      ctx.strokeStyle = "red";
      ctx.lineWidth = 2;
      ctx.strokeRect(x * scale, y * scale, w * scale, h * scale);
    };
    drawCanvas();

    // Center the bounding box in the scroll area
    const scrollToBox = () => {
      const container = scrollContainerRef.current;
      if (!container) return;

      const [x, y, w, h] = selectedAnnForPreview.bbox;
      const cw = container.clientWidth;
      const ch = container.clientHeight;

      const centerX = (x + w / 2) * scale;
      const centerY = (y + h / 2) * scale;

      let scrollLeft = centerX - cw / 2;
      let scrollTop = centerY - ch / 2;

      scrollLeft = Math.max(0, Math.min(scrollLeft, modalCanvasRef.current.width - cw));
      scrollTop = Math.max(0, Math.min(scrollTop, modalCanvasRef.current.height - ch));

      container.scrollLeft = scrollLeft;
      container.scrollTop = scrollTop;
    };
    requestAnimationFrame(scrollToBox);
  }, [previewVisible, selectedAnnForPreview, scale, image]);

  // Collect all category & BaseType names
  const allCategoryNames = Object.keys(groupedData).sort();
  const baseTypeSet = new Set();
  allCategoryNames.forEach((cat) => {
    Object.keys(groupedData[cat]).forEach((bt) => baseTypeSet.add(bt));
  });
  const allBaseTypes = Array.from(baseTypeSet).sort();

  // Update filters based on user choice
  const handleExplorerCategoryChange = (val) => {
    setSelectedExplorerCategory(val);
  };
  const handleExplorerBaseTypeChange = (val) => {
    setSelectedExplorerBaseType(val);
  };

  // Derive allowed categories & base types from filters
  let allowedCategories =
    selectedExplorerCategory === "All"
      ? allCategoryNames
      : allCategoryNames.filter((c) => c === selectedExplorerCategory);

  let allowedBaseTypes =
    selectedExplorerBaseType === "All"
      ? allBaseTypes
      : allBaseTypes.filter((bt) => bt === selectedExplorerBaseType);

  // Ensure baseTypes exist within the selected category if that category isn't "All"
  if (selectedExplorerCategory !== "All") {
    const cat = selectedExplorerCategory;
    if (!groupedData[cat]) {
      allowedBaseTypes = [];
    } else {
      allowedBaseTypes = allowedBaseTypes.filter((bt) => groupedData[cat][bt]);
    }
  }

  // Ensure categories exist for the selected baseType if it's not "All"
  if (selectedExplorerBaseType !== "All") {
    allowedCategories = allowedCategories.filter((catName) => {
      return groupedData[catName] && groupedData[catName][selectedExplorerBaseType];
    });
  }

  // Count how many annotations are shown
  let totalAnnCount = 0;
  allowedCategories.forEach((catName) => {
    allowedBaseTypes.forEach((bt) => {
      totalAnnCount += groupedData[catName][bt]?.length || 0;
    });
  });

  // Render UI
  return (
    <div style={{ padding: 10 }}>
      <h3>Explorer - By Category & BaseType</h3>

      {/* Filter controls always visible */}
      <div style={{ marginBottom: 10, display: "flex", gap: 30 }}>
        <div>
          <span>Category Filter: </span>
          <Select
            style={{ width: 160 }}
            onChange={handleExplorerCategoryChange}
            value={selectedExplorerCategory}
          >
            <Option value="All">All</Option>
            {allCategoryNames.map((cat) => (
              <Option key={cat} value={cat}>
                {cat}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <span>BaseType Filter: </span>
          <Select
            style={{ width: 160 }}
            onChange={handleExplorerBaseTypeChange}
            value={selectedExplorerBaseType}
          >
            <Option value="All">All</Option>
            {allBaseTypes.map((bt) => (
              <Option key={bt} value={bt}>
                {bt}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {/* If no data matches the filters, show a message but keep filters on screen */}
      {allowedCategories.length === 0 || allowedBaseTypes.length === 0 || totalAnnCount === 0 ? (
        <Empty
          description={`No data found for Category: ${selectedExplorerCategory} | BaseType: ${selectedExplorerBaseType}`}
        />
      ) : (
        // Otherwise, build our snippet table
        <table
          style={{
            display: "block",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <thead>
            <tr>
              <th style={{ border: "1px solid #ccc", padding: 6, minWidth: 150 }}>Category</th>
              {allowedBaseTypes.map((bt) => (
                <th
                  key={bt}
                  style={{ border: "1px solid #ccc", padding: 6, minWidth: 200 }}
                >
                  {bt}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allowedCategories.map((catName) => (
              <tr key={catName}>
                <td
                  style={{
                    border: "1px solid #ccc",
                    padding: 6,
                    minWidth: 150,
                    verticalAlign: "top",
                  }}
                >
                  {catName}
                </td>
                {allowedBaseTypes.map((bt) => {
                  const annList = groupedData[catName][bt] || [];
                  return (
                    <td
                      key={bt}
                      style={{
                        border: "1px solid #ccc",
                        verticalAlign: "top",
                        padding: 6,
                        minWidth: 200, // Ensure adequate column width
                      }}
                    >
                      {annList.length > 0 && (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {annList.map((ann) => {
                            const snippetUrl = getSnippetForAnn(ann);
                            if (!snippetUrl) return null;
                            return (
                              <img
                                key={ann.id}
                                src={snippetUrl}
                                alt="snippet"
                                style={{
                                  width: 50,
                                  height: "auto",
                                  margin: 2,
                                  border: "1px solid #999",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleSnippetClick(ann)}
                              />
                            );
                          })}
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal with zoomed canvas preview */}
      <Modal
        visible={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        footer={null}
        width="90vw"
        destroyOnClose={false}
        forceRender
        bodyStyle={{ height: "80vh", overflow: "hidden" }}
      >
        <div style={{ marginBottom: 10, textAlign: "center" }}>
          <span style={{ marginRight: 10 }}>Zoom:</span>
          <Slider
            style={{ width: 300, display: "inline-block" }}
            min={0.5}
            max={4}
            step={0.1}
            value={scale}
            onChange={(val) => setScale(val)}
          />
          <span style={{ marginLeft: 10 }}>{scale.toFixed(1)}x</span>
        </div>

        <div
          ref={scrollContainerRef}
          style={{
            width: "100%",
            height: "calc(80vh - 50px)",
            overflow: "auto",
            border: "1px solid #ccc",
          }}
        >
          <canvas ref={modalCanvasRef} style={{ display: "block", margin: "0 auto" }} />
        </div>
      </Modal>
    </div>
  );
}
