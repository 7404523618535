import React, { useRef, useState, useEffect, useCallback } from "react";
import { Button, Upload, Tabs, Slider } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import BaseTypeExplorer from "./BaseTypeExplorer";

const { TabPane } = Tabs;

const CocoUploader = () => {
  // Tab state (Viewer / Explorer)
  const [activeTab, setActiveTab] = useState("viewer");

  // Image management: base64 data and <img> object
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [image, setImage] = useState(null);

  // COCO annotations and categories
  const [annotations, setAnnotations] = useState([]);
  const [categories, setCategories] = useState([]);

  // Filtering and color styling for bounding boxes
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryColors, setCategoryColors] = useState({});

  // Zoom scale
  const [viewerScale, setViewerScale] = useState(1);

  // Canvas reference
  const viewerCanvasRef = useRef(null);

  // Handle uploading an image and converting it to base64
  const handleImageUpload = ({ file }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageDataUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  // Convert the base64 string into an actual <img> object
  useEffect(() => {
    if (!imageDataUrl) {
      setImage(null);
      return;
    }
    const img = new Image();
    img.src = imageDataUrl;
    img.onload = () => {
      setImage(img);
    };
  }, [imageDataUrl]);

  // Handle JSON upload for COCO-style annotations
  const handleJsonUpload = ({ file }) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const cocoJson = JSON.parse(evt.target.result);
      setAnnotations(cocoJson.annotations || []);
      setCategories(cocoJson.categories || []);
      setSelectedCategory(null);
      setCategoryColors(generateCategoryColors(cocoJson.categories || []));
    };
    reader.readAsText(file);
  };

  // Generate random color per category
  const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 40) + 60;
    const lightness = Math.floor(Math.random() * 30) + 40;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  // Assign a color to each category ID
  const generateCategoryColors = (catArray) => {
    const colors = {};
    catArray.forEach((cat) => {
      colors[cat.id] = getRandomColor();
    });
    return colors;
  };

  // Return a category name by ID or "Unknown" if not found
  const getCategoryName = (id) => {
    if (!categories || categories.length === 0) return "Unknown";
    const cat = categories.find((c) => String(c.id) === String(id));
    return cat ? cat.name : "Unknown";
  };

  // Filter annotations by selected category
  const getFilteredAnnotations = () => {
    if (selectedCategory === null) return annotations;
    return annotations.filter((ann) => ann.category_id === selectedCategory);
  };

  // Draw the image and bounding boxes
  const drawCanvas = useCallback(() => {
    if (!image || !viewerCanvasRef.current) return;

    const canvas = viewerCanvasRef.current;
    const ctx = canvas.getContext("2d");

    // Compute scaled dimensions
    const scaledW = image.width * viewerScale;
    const scaledH = image.height * viewerScale;

    // Resize and clear the canvas
    canvas.width = scaledW;
    canvas.height = scaledH;
    ctx.clearRect(0, 0, scaledW, scaledH);

    // Draw the scaled image
    ctx.drawImage(image, 0, 0, scaledW, scaledH);

    if (!annotations || annotations.length === 0) return;

    // Draw each bounding box
    const filtered = getFilteredAnnotations();
    filtered.forEach((ann) => {
      const [x, y, w, h] = ann.bbox;
      const color = categoryColors[ann.category_id] || "red";

      ctx.strokeStyle = color;
      ctx.lineWidth = 3;
      ctx.strokeRect(
        x * viewerScale,
        y * viewerScale,
        w * viewerScale,
        h * viewerScale
      );

      ctx.fillStyle = color;
      ctx.font = "14px Arial";
      ctx.fillText(
        getCategoryName(ann.category_id),
        x * viewerScale,
        y * viewerScale - 5
      );
    });
  }, [image, annotations, categoryColors, viewerScale, selectedCategory, categories]);

  // Redraw on relevant state changes
  useEffect(() => {
    drawCanvas();
  }, [drawCanvas, activeTab]);

  // Redraw on window resize
  useEffect(() => {
    const handleResize = () => {
      drawCanvas();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [drawCanvas]);

  // Reset all states
  const handleReset = () => {
    setImageDataUrl(null);
    setImage(null);
    setAnnotations([]);
    setCategories([]);
    setSelectedCategory(null);
    setCategoryColors({});
    setViewerScale(1);
  };

  // Check if any annotation uses "BaseType"
  const hasAnyBaseType = annotations.some(
    (ann) => ann.tags?.BaseType?.length > 0
  );

  // Simple sidebar for category filtering
  const SideBar = () => {
    return (
      <div
        style={{
          width: 100,
          borderRight: "1px solid gray",
          padding: 10,
          fontSize: 18,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          <li
            key="all"
            style={{
              cursor: "pointer",
              padding: "5px 10px",
              backgroundColor: selectedCategory === null ? "#ccc" : "#eee",
              marginBottom: 5,
              border: selectedCategory === null ? "2px solid black" : "none",
            }}
            onClick={() => setSelectedCategory(null)}
          >
            All
          </li>
          {categories
            .filter((cat) => cat.name && cat.name !== "Unknown")
            .map((cat) => (
              <li
                key={cat.id}
                style={{
                  cursor: "pointer",
                  padding: "5px 10px",
                  backgroundColor: categoryColors[cat.id],
                  border:
                    selectedCategory === cat.id ? "2px solid black" : "none",
                  marginBottom: 5,
                }}
                onClick={() => setSelectedCategory(cat.id)}
              >
                {cat.name}
              </li>
            ))}
        </ul>
      </div>
    );
  };

  // Main layout for upload, zoom, and canvas
  const MainContent = () => {
    return (
      <div style={{ flex: 1, padding: 10, display: "flex", flexDirection: "column", width: "80%" }}>
        <div style={{ marginBottom: 10 }}>
          <Upload
            accept="image/*"
            showUploadList={false}
            customRequest={({ file }) => handleImageUpload({ file })}
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>

          <Upload
            accept=".json"
            showUploadList={false}
            customRequest={({ file }) => handleJsonUpload({ file })}
          >
            <Button icon={<UploadOutlined />}>Upload Annotations</Button>
          </Upload>

          <Button onClick={handleReset} style={{ marginLeft: 10 }}>
            Reset
          </Button>
        </div>

        {image && (
          <div style={{ marginBottom: 20, padding: 20 }}>
            <Slider
              min={0.5}
              max={4}
              step={0.1}
              marks={{ 0.5: "0.5x", 1: "1x", 2: "2x", 4: "4x" }}
              value={viewerScale}
              onChange={setViewerScale}
              style={{ width: 300, display: "inline-block" }}
            />
            <span style={{ marginLeft: 40 }}>{viewerScale.toFixed(1)}x</span>

            <Button
              style={{ margin: 10 }}
              onClick={() => setViewerScale((s) => Math.min(s + 0.1, 4))}
            >
              Zoom In
            </Button>
            <Button
              style={{ margin: 10 }}
              onClick={() => setViewerScale((s) => Math.max(s - 0.1, 0.5))}
            >
              Zoom Out
            </Button>
          </div>
        )}

        <div style={{ flex: 1, border: "1px solid #ccc", overflow: "auto" }}>
          {image && (
            <canvas
              ref={viewerCanvasRef}
              style={{ display: "block", margin: "0 auto" }}
            />
          )}
        </div>
      </div>
    );
  };

  const bothSelected = !!(image && annotations.length);

  return (
    <Tabs
      activeKey={activeTab}
      onChange={(key) => setActiveTab(key)}
      style={{ width: "100%" }}
      destroyInactiveTabPane={false}
    >
      <TabPane tab="Viewer" key="viewer">
        {bothSelected ? (
          <div style={{ display: "flex", height: "80vh" }}>
            <SideBar />
            <MainContent />
          </div>
        ) : (
          <div style={{ padding: 10 }}>
            <h4>Annotation Viewer (Zoomable)</h4>
            <div style={{ marginBottom: 10 }}>
              <Upload
                accept="image/*"
                showUploadList={false}
                customRequest={({ file }) => handleImageUpload({ file })}
              >
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>

              <Upload
                accept=".json"
                showUploadList={false}
                customRequest={({ file }) => handleJsonUpload({ file })}
              >
                <Button icon={<UploadOutlined />}>Upload Annotations</Button>
              </Upload>

              <Button onClick={handleReset} style={{ marginLeft: 10 }}>
                Reset
              </Button>
            </div>
          </div>
        )}
      </TabPane>

      {hasAnyBaseType && (
        <TabPane tab="Explorer" key="explorer">
          <BaseTypeExplorer
            image={image}
            annotations={annotations}
            categories={categories}
            getCategoryName={getCategoryName}
          />
        </TabPane>
      )}
    </Tabs>
  );
};

export default CocoUploader;
