import * as React from "react";
import { Layout, Typography } from "antd";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Home = () => {
  return (
    <Layout
      style={{
        width: "100%",
        backgroundImage: "url('./images/background.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Content
        style={{
          maxWidth: "900px",
          width: "100%",
          background: "rgba(255, 255, 255, 0.95)",
          padding: "30px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Paragraph>
          <Title
            level={3}
            style={{ fontFamily: "Swift Neue LT W01", textAlign: "center" }}
          >
            {`Welcome to D-Scribes!`}
          </Title>
        </Paragraph>
        <Paragraph>
          <Title
            level={5}
            style={{ fontFamily: "Verdana", textAlign: "justify", lineHeight: 1.6 }}
          >
            {`Welcome to the website of the SNSF Ambizione project "Reuniting fragments, identifying scribes and characterizing scripts: the Digital paleography of Greek and Coptic papyri" (September 2018 – August 2022)`}
          </Title>
        </Paragraph>
        <Paragraph
          style={{
            fontFamily: "Verdana",
            textAlign: "justify",
            lineHeight: 1.6,
          }}
        >
          {`Greek and Coptic papyri preserved in the sands of Egypt are one of our main sources for Classical and early Christian literature. They are also crucial for the field of Ancient History of the Eastern Mediterranean world, as the texts found written in them span from the Hellenistic period to the Middle Ages. The papyri are often incomplete, however, broken accidentally during the course of time or, in more recent periods, intentionally cut to be sold in pieces in the antiquities market. It is precisely because of the fragmentary nature of these irreplaceable texts that palaeography, the study of ancient handwritings, is of great importance to papyrologists. First, palaeography helps to identify the specific handwriting of one individual over several fragments. Secondly, it provides dating arguments in the absence of other criteria, since Greek and Coptic scripts have developed consistently over time.`}
        </Paragraph>
        <Paragraph
          style={{
            fontFamily: "Verdana",
            textAlign: "justify",
            lineHeight: 1.6,
          }}
        >
          {`The present research project aims to lay the foundation for an online interface through which papyrologists would be able to look for similar – or identical – handwritings to a given papyrus and for typical samples of writing for a given period. To reach this ambitious goal, this project will first focus on three distinct and complementary case studies:`}
        </Paragraph>
        <ul
          style={{
            paddingLeft: "20px",
            fontFamily: "Verdana",
            lineHeight: 1.6,
            textAlign: "justify",
          }}
        >
          <li>{`The papyri which contain Homer’s Iliad, written in Ancient Greek;`}</li>
          <li>{`The richest archive of the Byzantine period which is called the archive of Dioscorus of Aphrodito written in Greek and Coptic (from the 6th c. A.D.);`}</li>
          <li>{`The archive of Papas, written in Coptic, whose papyri were found broken in a jar and thus represent a real jigsaw puzzle for papyrologists.`}</li>
        </ul>
      </Content>
    </Layout>
  );
};

export default Home;
