import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, List, Space, Divider } from "antd";
import axios from "axios";

interface Item {
  id: string;
  name: string;
}

const ImageArea: React.FC = () => {
  const params = useParams<{ type: string; collection_id: string }>();
  const history = useHistory();
  const [data, setData] = useState<Item[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch data on mount or when params change
  const fetchData = useCallback(async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(
        `https://db.d-scribes.philhist.unibas.ch/api/showcase/${params.type}/${params.collection_id}`
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // End loading
    }
  }, [params.type, params.collection_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const navigateTo = (path: string) => {
    history.push(path);
  };

  const renderDataList = () => (
    <div style={{ width: "60%", margin: "0 auto" }}>
      <List
        itemLayout="horizontal"
        dataSource={data || []}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <a style={{ fontSize: 22 }} onClick={() => navigateTo(`/papyri/${item.id}`)}>
                  {item.name}
                </a>
              }
            />
            <Button onClick={() => navigateTo(`/papyri/${item.id}`)}>Show</Button>
          </List.Item>
        )}
      />
    </div>
  );

  return (
    <div>
      <Space wrap style={{ display: "flex", justifyContent: "center" }}>
        <h4>
          Click{" "}
          <a style={{ color: "#2192FF" }} onClick={() => navigateTo(`/listWriters`)}>
            here
          </a>{" "}
          to get Dioscorus by writer.
        </h4>
      </Space>
      <Space wrap style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type={params.type === "subscription" ? "primary" : "default"}
          onClick={() => navigateTo(`/papyrus/subscription/${params.collection_id}`)}
        >
          Subscription
        </Button>
        <Button
          type={params.type === "paelographicAssignation" ? "primary" : "default"}
          onClick={() => navigateTo(`/papyrus/paelographicAssignation/${params.collection_id}`)}
        >
          Paelographic Assignation
        </Button>
        <Button
          type={params.type === "securelyAttributedText" ? "primary" : "default"}
          onClick={() => navigateTo(`/papyrus/securelyAttributedText/${params.collection_id}`)}
        >
          Securely Attributed Text
        </Button>
      </Space>
      <Divider />
      {loading ? <p>Loading...</p> : renderDataList()}
    </div>
  );
};

export default ImageArea;
