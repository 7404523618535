import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, List, Avatar, Spin, Alert } from "antd";
import axios from "axios";

interface PapyrusData {
  id: string;
  name: string;
}

const Papyrus = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [data, setData] = useState<PapyrusData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `https://db.d-scribes.philhist.unibas.ch/api/showcase/papyri/${id}`
        );
        setData(response.data);
      } catch (err) {
        setError("Failed to fetch data. Please try again later.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleShow = useCallback(
    (imageId: string) => {
      history.push(`/image/${imageId}`);
    },
    [history]
  );

  const renderList = useCallback(
    (data: PapyrusData[]) => (
      <div style={{ width: "60%", margin: "0 auto" }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{ height: 100, width: 100 }}
                    src={`https://db.d-scribes.philhist.unibas.ch/iiif/${item.name}/0,0,0,0/full/0/default.jpg`}
                  />
                }
                title={
                  <a
                    style={{ fontSize: 22 }}
                    onClick={() => handleShow(item.id)}
                  >
                    {item.name}
                  </a>
                }
              />
              <Button onClick={() => handleShow(item.id)}>Show</Button>
            </List.Item>
          )}
        />
      </div>
    ),
    [handleShow]
  );

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return <div>{data ? renderList(data) : <p>No data available.</p>}</div>;
};

export default Papyrus;
